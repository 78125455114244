<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb2">
      <el-breadcrumb-item>考勤情况</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <i class="el-icon-folder"></i>
      <span style="margin-left:10px">组织列表</span>
      <div class="search-bar">
        <el-input
          v-model="formInline.oname"
          placeholder="请输入组织名称进行查询"
          style="width:240px;"
          size="small"
          class="inputstyle"
          @keydown.enter.native="searchOrg"
          suffix-icon="el-icon-search"
        >
        </el-input>
        <el-button plain type="primary" size="small" @click="clearSearch"
          >重置</el-button
        >
        <el-button type="primary" size="small" @click="searchOrg"
          >查询</el-button
        >
      </div>
      <el-table
        :data="listData"
        border
        stripe
        style="width: 100%"
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column
          label="序号"
          type="index"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column
          label="组织码"
          prop="ocode"
          align="center"
        ></el-table-column>
        <el-table-column
          label="组织名称"
          prop="oname"
          align="center"
        ></el-table-column>
        <el-table-column
          label="地址"
          prop="address"
          align="center"
        ></el-table-column>
        <el-table-column
          label="邮箱"
          prop="email"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="status"
          sortable
          label="操作"
          align="center"
          width="300"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="handleAttendance(scope.$index, scope.row)"
              >查看考勤</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next,jumper"
          :current-page.sync="formInline.pageNum"
          :page-size="formInline.pageSize"
          :page-sizes="[5, 10, 15]"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import qs from 'qs'
const defaultFormInline = {
  pageNum: 1,
  pageSize: 5,
  oname: null
}
export default {
  data() {
    return {
      uid: '', //用户id
      sid: '', //上级的id
      searchName: '',
      listData: [],
      // 分页
      formInline: {
        oname: '',
        pageNum: 1,
        pageSize: 5
      },
      total: 0
    }
  },
  created() {
    this.uid = window.sessionStorage.getItem('uid')
    this.getList()
  },
  methods: {
    //重置
    clearSearch() {
      this.formInline = Object.assign({}, defaultFormInline)
      this.getList()
    },
    //查询
    async searchOrg() {
      const { data: res } = await this.$http.get('/org/selectbyoname', {
        params: {
          oname: this.formInline.oname,
          pageNum: this.formInline.pageNum,
          pageSize: this.formInline.pageSize,
          uid: this.uid
        }
      })
      if (res.data != null) {
        this.listData = res.data
        this.total = res.total
        this.$message.success('查询成功')
      }
    },
    // 实现分页
    // 更改了每页大小
    handleSizeChange(val) {
      this.formInline.pageNum = 1
      this.formInline.pageSize = val
      this.getList()
    },
    // 更改了第几页
    handleCurrentChange(val) {
      this.formInline.pageNum = val
      this.getList()
    },
    //查看我的组织详情
    handleAttendance(index, row) {
      // window.sessionStorage.setItem('fatherName', row.oname)
      // window.sessionStorage.setItem('groupCode', row.groupCode)
      // window.sessionStorage.setItem('cid', row.cid)
      // window.sessionStorage.setItem('acttype', row.actType)
      // window.sessionStorage.setItem('createtime', row.createTime)
      // window.sessionStorage.setItem('className', row.className)
      // window.sessionStorage.setItem('actId', row.actId)
      // window.sessionStorage.setItem('soid', row.oid)
      this.$router.push({
        path: '/zuzhi/organizationList/myOrganizationList1',
        query: { oid: row.oid, fatherName: row.oname }
      })
    },
    //获取列表组织
    async getList() {
      const { data: res } = await this.$http.get('/org/selectbyuid', {
        params: {
          uid: this.uid,
          pageNum: this.formInline.pageNum,
          pageSize: this.formInline.pageSize
        }
      })
      if (res != null) {
        this.listData = res.data
        this.total = res.total
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  .inputstyle {
    margin-right: 10px;
  }
}
.search {
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;
}
.search span {
  color: #409eff;
  font-size: 25px;
  font-weight: bold;
  margin-right: 5px;
  align-items: center;
}
</style>
